<template>
  <footer class="p-4 text-center">
    © {{ new Date().getFullYear() }} — Xtreme Admin by
    <a href="https://www.wrappixel.com/">wrappixel.com</a>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>